import Flickity from "flickity";

const flickitySliders = [];

export function init() {
  const sliders = document.querySelectorAll('[data-module="item-slider"]');
  sliders.forEach(slider => {
    flickitySliders.push(
      new Flickity(slider, {
        cellAlign: 'left',
        wrapAround: true
      })
    );
  });
}
